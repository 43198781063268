import { React, useEffect, useRef, useState, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, Select, MenuItem, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import axios from 'axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { socket } from './socket';

dayjs.extend(utc);
dayjs.extend(timezone);

var api = axios.create({ withCredentials: true });

function Reports(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [hourly, setHourly] = useState(null);
    const [dailyMetric, setDailyMetric] = useState(null);
    const [dailyMetricTime, setDailyMetricTime] = useState(dayjs('1970-01-01T02:00'));
    const [dailySales, setDailySales] = useState(null);
    const [dailySalesTime, setDailySalesTime] = useState(dayjs('1970-01-01T21:00'));
    const [weekly, setWeekly] = useState(null);
    const [weeklyTime, setWeeklyTime] = useState(dayjs('1970-01-01T02:00'));
    const [weeklyDay, setWeeklyDay] = useState('0');
    const user = props.user;
    const isConnected = props.isConnected;

    function getSubscriptions() {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.post(
                process.env.REACT_APP_BASEURL + '/api/schedule/get',
                { user: user },
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var hourly = false;
                var dailyMetric = false;
                var dailyMetricTime = dayjs('1970-01-01T02:00');
                var dailySales = false;
                var dailySalesTime = dayjs('1970-01-01T21:00');
                var weekly = false;
                var weeklyTime = dayjs('1970-01-01T02:00');
                var weeklyDay = '0';

                response.data.forEach((sub) => {
                    if (sub.data.type === 'hourly') {
                        hourly = true;
                    } else if (sub.data.type === 'dailyMetric') {
                        dailyMetric = true;
                        dailyMetricTime = dayjs(sub.nextRunAt).tz(dayjs.tz.guess());
                    } else if (sub.data.type === 'dailySales') {
                        dailySales = true;
                        dailySalesTime = dayjs(sub.nextRunAt).tz(dayjs.tz.guess());
                    } else if (sub.data.type === 'weekly') {
                        weekly = true;
                        weeklyTime = dayjs(sub.nextRunAt).tz(dayjs.tz.guess());
                        weeklyDay = dayjs(sub.nextRunAt).tz(dayjs.tz.guess()).day();
                    }
                });

                setHourly(hourly);
                setDailyMetric(dailyMetric);
                setDailyMetricTime(dailyMetricTime);
                setDailySales(dailySales);
                setDailySalesTime(dailySalesTime);
                setWeekly(weekly);
                setWeeklyTime(weeklyTime);
                setWeeklyDay(weeklyDay);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });
        });
    }

    function toggleHourlyInvoice() {
        if (hourly) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/delete',
                    {
                        user: user,
                        type: 'hourly'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Unsubscribed from Hourly Invoice Register.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'hourly',
                        schedule: `0 7-21 * * *`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Subscribed to Hourly Invoice Register.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        }
    }

    function toggleDailyMetrics() {
        if (dailyMetric) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/delete',
                    {
                        user: user,
                        type: 'dailyMetric'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Unsubscribed from Daily Metrics Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'dailyMetric',
                        schedule: `${dailyMetricTime.minute()} ${dailyMetricTime.hour()} * * *`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Subscribed to Daily Metrics Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        }
    }

    function changeDailyMetricTime(newValue) {
        if (dailyMetric) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                setDailyMetricTime(newValue);
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'dailyMetric',
                        schedule: `${newValue.minute()} ${newValue.hour()} * * *`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Daily Metrics Overview Time Updated.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            setDailyMetricTime(newValue);
        }
    }

    function toggleDailySales() {
        if (dailySales) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/delete',
                    {
                        user: user,
                        type: 'dailySales'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Unsubscribed from Daily Sales Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'dailySales',
                        schedule: `${dailySalesTime.minute()} ${dailySalesTime.hour()} * * *`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Subscribed to Daily Sales Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        }
    }

    function changeDailySalesTime(newValue) {
        if (dailySales) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                setDailySalesTime(newValue);
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'dailySales',
                        schedule: `${newValue.minute()} ${newValue.hour()} * * *`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Daily Sales Overview Time Updated.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            setDailySalesTime(newValue);
        }
    }

    function toggleWeeklySales() {
        if (weekly) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/delete',
                    {
                        user: user,
                        type: 'weekly'
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Unsubscribed from Weekly Sales Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'weekly',
                        schedule: `${weeklyTime.minute()} ${weeklyTime.hour()} * * ${weeklyDay}`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Subscribed to Weekly Sales Overview.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        }
    }

    function changeWeeklyTime(newValue) {
        if (weekly) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                setWeeklyTime(newValue);
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'weekly',
                        schedule: `${newValue.minute()} ${newValue.hour()} * * ${weeklyDay}`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Weekly Sales Overview Time Updated.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            setWeeklyTime(newValue);
        }
    }

    const handleDayChange = (event) => {
        if (weekly) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                setWeeklyDay(event.target.value);
                api.post(
                    process.env.REACT_APP_BASEURL + '/api/schedule/start',
                    {
                        user: user,
                        type: 'weekly',
                        schedule: `${weeklyTime.minute()} ${weeklyTime.hour()} * * ${event.target.value}`,
                        timezone: dayjs.tz.guess()
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    getSubscriptions();
                    toast.success(`Weekly Sales Overview Time Updated.`);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                        toast.error(`Scheduling Error`);
                    }
                });
            });
        } else {
            setWeeklyDay(event.target.value);
        }
    }

    useEffect(() => {
        getSubscriptions();
    }, []);

    if (hourly !== null && dailyMetric !== null && dailySales !== null && weekly !== null) {
        return (
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Report Name</TableCell>
                                    <TableCell align="right">Data Provided</TableCell>
                                    <TableCell align="right">Schedule</TableCell>
                                    <TableCell align="right">Subscribe</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='hourly_invoice'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    hover
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" color='primary'>
                                            Hourly Invoice Register
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        Current Day:&nbsp;<i>Invoice, Event Date, Event, Qty, Section, Row, Ticket Sale, Cost, Profit, Customer</i>
                                    </TableCell>
                                    <TableCell align="right">
                                        7:00AM - 9:00PM
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={hourly}
                                            color='success'
                                            onClick={() => {
                                                toggleHourlyInvoice();
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='daily_metrics'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    hover
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" color='primary'>
                                            Daily Metric Overview
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        Yesterday/7d/30d/90d/120d/150d/180d/YTD:&nbsp;<i>Purchases, Sales, Profit</i>
                                    </TableCell>
                                    <TableCell align="right">
                                        <MobileTimePicker
                                            value={dailyMetricTime}
                                            onChange={(newValue) => changeDailyMetricTime(newValue)}
                                            sx={{ width: '100px' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={dailyMetric}
                                            color='success'
                                            onClick={() => {
                                                toggleDailyMetrics();
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='daily_sales'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    hover
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" color='primary'>
                                            Daily Sales Overview
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        Current Day:&nbsp;<i>Sales, Costs, Profit, Ticket/Event/Invoice Counts, Event Listing</i>
                                    </TableCell>
                                    <TableCell align="right">
                                        <MobileTimePicker
                                            value={dailySalesTime}
                                            onChange={(newValue) => changeDailySalesTime(newValue)}
                                            sx={{ width: '100px' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={dailySales}
                                            color='success'
                                            onClick={() => {
                                                toggleDailySales();
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    key='weekly_sales'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    hover
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant="body1" color='primary'>
                                            Weekly Sales Overview
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        Last 7 Days (Per Day):&nbsp;<i>Sales, Costs, Profit, Ticket/Event/Invoice Counts</i>
                                    </TableCell>
                                    <TableCell align="right" sx={{ minWidth: '260px' }}>
                                        <Select
                                            labelId="weekly-day-label"
                                            id="weekly-day"
                                            value={weeklyDay}
                                            onChange={handleDayChange}
                                            sx={{ marginRight: '5px' }}
                                        >
                                            <MenuItem value='1'>Monday</MenuItem>
                                            <MenuItem value='2'>Tuesday</MenuItem>
                                            <MenuItem value='3'>Wednesday</MenuItem>
                                            <MenuItem value='4'>Thursday</MenuItem>
                                            <MenuItem value='5'>Friday</MenuItem>
                                            <MenuItem value='6'>Saturday</MenuItem>
                                            <MenuItem value='0'>Sunday</MenuItem>
                                        </Select>
                                        <MobileTimePicker
                                            value={weeklyTime}
                                            onChange={(newValue) => changeWeeklyTime(newValue)}
                                            sx={{ width: '100px' }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={weekly}
                                            color='success'
                                            onClick={() => {
                                                toggleWeeklySales();
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </LocalizationProvider>
            </>
        );
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }
}

export default Reports;
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';

LicenseInfo.setLicenseKey('78c12cf013affab01f25b8d3866d0990Tz05MzQ5NCxFPTE3NTE0NjUwOTAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0DOMAIN}
    clientId={process.env.REACT_APP_AUTH0CLIENTID}
    cacheLocation='localstorage'
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0AUDIENCE,
      scope: process.env.REACT_APP_AUTH0SCOPE
    }}
  >
    <App />
  </Auth0Provider>,
);
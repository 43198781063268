import { React, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    ResponsiveChartContainer,
    BarPlot,
    LinePlot,
    MarkPlot,
    ChartsTooltip,
    ChartsYAxis
} from '@mui/x-charts';
import {
    Box, CircularProgress, Grid,
    Table, TableBody, TableCell, TableContainer, TableRow,
    Card, CardHeader, CardContent
} from '@mui/material';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';

import { socket } from './socket';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFractionFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
}).format;

var api = axios.create({ withCredentials: true });

function Events(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const [event, setEvent] = useState(null);
    const event_id = props.event_id;
    const current_broker = props.current_broker;
    const isConnected = props.isConnected;

    function getEvent() {
        if (event_id) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            }).then((token) => {
                api.get(
                    `${process.env.REACT_APP_BASEURL}/api/dashboard/event/${event_id}/${current_broker}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                ).then((response) => {
                    setEvent(response.data);

                    var c = [
                        { field: 'count', headerName: 'Open Qty', flex: .25, minWidth: 50, type: 'number' },
                        { field: 'scount', headerName: 'Sold Qty', flex: .25, minWidth: 50, type: 'number' },
                        { field: 'section', headerName: 'Section', flex: .25, minWidth: 50, type: 'string' },
                        { field: 'row', headerName: 'Row', flex: .25, minWidth: 50, type: 'string' },
                        { field: 'low', headerName: 'Low Seat', flex: .25, minWidth: 50, type: 'number' },
                        { field: 'high', headerName: 'High Seat', flex: .25, minWidth: 50, type: 'number' },
                        {
                            field: 'cost', headerName: 'Open Cost', flex: .25, minWidth: 100, type: 'number',
                            renderCell: (params) => {
                                return `${currencyFormatter(params.value)}`;
                            }
                        },
                        {
                            field: 'retail', headerName: 'Retail', flex: .25, minWidth: 100, type: 'number',
                            renderCell: (params) => {
                                return `${currencyFormatter(params.value)}`;
                            }
                        },
                        {
                            field: 'avg', headerName: 'Retail Avg Ticket', flex: .25, minWidth: 125, type: 'number',
                            renderCell: (params) => {
                                return `${currencyFormatter(params.value)}`;
                            }
                        },
                    ];
                    setColumns(c);

                    var r = [];
                    response.data.open_tickets.forEach((row, x) => {
                        var sold_count = 0;
                        response.data.sold_tickets.forEach((s) => {
                            if (s._id.section === row._id.section && s._id.row === row._id.row) {
                                sold_count = s.sold_count;
                            }
                        });
                        r.push({
                            id: x,
                            count: row.open_count,
                            scount: sold_count,
                            section: row._id.section,
                            row: row._id.row,
                            low: row.low_seat,
                            high: row.high_seat,
                            cost: row.cost,
                            retail: row.retail,
                            avg: row.avg_ticket
                        });
                    });
                    setRows(r);
                }).catch((err) => {
                    if (err.message !== 'canceled') {
                        console.log(err);
                    }
                });
            });
        }
    }

    useEffect(() => {
        getEvent();
    }, [event_id]);

    if (event_id && event !== null) {
        var max_customer_label = 0;
        event.sold_by_customer.forEach((x, i) => {
            if (x._id) {
                if ((x._id.length * 6.5) > max_customer_label) {
                    max_customer_label = (x._id.length * 6.5);
                }
            }
        });

        return (
            <span style={{ margin: '15px' }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} lg={6}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={currencyFormatter(event.sold_by_customer.reduce((n, { total_sold }) => n + total_sold, 0))}
                                    subheader='Total Event Sales'
                                    titleTypographyProps={{ color: '#3B3061' }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <ResponsiveChartContainer
                                        height={250}
                                        margin={{
                                            left: max_customer_label,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        yAxis={[
                                            { scaleType: 'band', dataKey: "_id" }
                                        ]}
                                        series={[
                                            {
                                                type: 'bar',
                                                dataKey: 'total_sold',
                                                color: '#41A949',
                                                valueFormatter: (value) => (
                                                    currencyFormatter(value) +
                                                    ' (' +
                                                    numberFormatter((value / event.sold_by_customer.reduce((n, { total_sold }) => n + total_sold, 0)) * 100)
                                                    + '%)'
                                                ),
                                                layout: 'horizontal'
                                            }
                                        ]}
                                        dataset={event.sold_by_customer}
                                    >
                                        <BarPlot borderRadius={10} />
                                        <ChartsTooltip />
                                        <ChartsYAxis disableLine disableTicks position='left' />
                                    </ResponsiveChartContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={numberFormatter(event.sold_by_day.reduce((n, { sold_count }) => n + sold_count, 0))}
                                    subheader='Total Event Tickets Sold'
                                    titleTypographyProps={{ color: '#3B3061' }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <ResponsiveChartContainer
                                        height={250}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: '_id',
                                                scaleType: 'band',
                                                min: event.sold_by_day.length > 0 ? event.sold_by_day[0]._id : dayjs().toDate(),
                                                max: event.sold_by_day.length > 0 ? event.sold_by_day[event.sold_by_day.length - 1]._id : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                id: 'dollar',
                                                max: Math.max(...event.sold_by_day.map(o => o.avg_ticket)) + (Math.max(...event.sold_by_day.map(o => o.avg_ticket)) * .1)
                                            },
                                            {
                                                id: 'count',
                                                max: Math.max(...event.sold_by_day.map(o => o.sold_count)) + (Math.max(...event.sold_by_day.map(o => o.sold_count)) * .1)
                                            }
                                        ]}
                                        series={[
                                            {
                                                type: 'bar',
                                                dataKey: 'sold_count',
                                                color: '#41A949',
                                                label: 'Total Sold',
                                                valueFormatter: (value) => (numberFormatter(value)),
                                                yAxisId: 'count'
                                            },
                                            {
                                                type: 'line',
                                                dataKey: 'low_ticket',
                                                color: '#fe5f55',
                                                label: 'Low Price',
                                                valueFormatter: (value) => (currencyFormatter(value)),
                                                yAxisId: 'dollar'
                                            },
                                            {
                                                type: 'line',
                                                dataKey: 'avg_ticket',
                                                color: '#577399',
                                                label: 'Avg Price',
                                                valueFormatter: (value) => (currencyFormatter(value)),
                                                yAxisId: 'dollar'
                                            }
                                        ]}
                                        dataset={event.sold_by_day}
                                    >
                                        <BarPlot borderRadius={10} />
                                        <LinePlot />
                                        <MarkPlot />
                                        <ChartsTooltip />
                                    </ResponsiveChartContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} md={12} lg={5.5}>
                        <Box flexGrow={1} elevation={5}>
                            <Card elevation={5} sx={{ width: '100%', height: '375px' }}>
                                <DataGridPro
                                    density="compact"
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                />
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3.25}>
                        <Box flexGrow={1}>
                            <Card elevation={5} sx={{ height: '375px' }}>
                                <CardHeader
                                    title='Profit/Loss Details'
                                    subheader={event.profit >= 0 ? `${currencyFormatter(event.profit)} Profit` : `${currencyFormatter(event.profit)} Loss`}
                                    subheaderTypographyProps={event.profit >= 0 ? { color: '#41A949' } : { color: '#AA1E23' }}
                                    sx={{ backgroundColor: 'gainsboro' }}
                                />
                                <CardContent>
                                    <TableContainer component={Box}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow key={'days'}>
                                                    <TableCell component="th" scope="row">Days Left</TableCell>
                                                    <TableCell align="right">{numberFractionFormatter(dayjs(event._id.event_datetime).diff(dayjs(), 'day', true))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'rt'}>
                                                    <TableCell component="th" scope="row">Remaining Potential Retail</TableCell>
                                                    <TableCell align="right">{event.hasOwnProperty('p_l') ? currencyFormatter(event.p_l.remaining_r) : currencyFormatter(0)}</TableCell>
                                                </TableRow>
                                                <TableRow key={'rc'}>
                                                    <TableCell component="th" scope="row">Remaining Cost</TableCell>
                                                    <TableCell align="right">{event.hasOwnProperty('p_l') ? currencyFormatter(event.p_l.remaining_c) : currencyFormatter(0)}</TableCell>
                                                </TableRow>
                                                <TableRow key={'tc'}>
                                                    <TableCell component="th" scope="row">Total Cost</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.cost)}</TableCell>
                                                </TableRow>
                                                <TableRow key={'ts'}>
                                                    <TableCell component="th" scope="row">Total Sold</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.sold)}</TableCell>
                                                </TableRow>
                                                <TableRow key={'tpl'}>
                                                    <TableCell component="th" scope="row">Total P/L</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.profit)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3.25}>
                        <Box flexGrow={1}>
                            <Card elevation={5} sx={{ height: '375px' }}>
                                <CardHeader
                                    title='Ticket Stats'
                                    subheader={`${numberFormatter(event.open_tickets.reduce((n, { open_count }) => n + open_count, 0))} Open Tickets`}
                                    sx={{ backgroundColor: 'gainsboro' }}
                                />
                                <CardContent>
                                    <TableContainer component={Box}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow key={'rt'}>
                                                    <TableCell component="th" scope="row">Open Tickets (Mine)</TableCell>
                                                    <TableCell align="right">{numberFormatter(event.open_tickets.reduce((n, { open_count }) => n + open_count, 0))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'ws'}>
                                                    <TableCell component="th" scope="row">Open Tickets (All Vendors)</TableCell>
                                                    <TableCell align="right">{numberFormatter(event.open_tickets_all.reduce((n, { open_count }) => n + open_count, 0))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'rc'}>
                                                    <TableCell component="th" scope="row">Lowest Open Price</TableCell>
                                                    <TableCell align="right">{currencyFormatter(Math.min(...event.open_tickets.map(o => o.low_ticket)))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'tc'}>
                                                    <TableCell component="th" scope="row">Highest Open Price</TableCell>
                                                    <TableCell align="right">{currencyFormatter(Math.max(...event.open_tickets.map(o => o.high_ticket)))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'ts'}>
                                                    <TableCell component="th" scope="row">Total Open Value</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.open_tickets.reduce((n, { retail }) => n + retail, 0))}</TableCell>
                                                </TableRow>
                                                <TableRow key={'apm'}>
                                                    <TableCell component="th" scope="row">Average Open Price (Mine)</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.open_tickets.reduce((n, { avg_ticket }) => n + avg_ticket, 0) / event.open_tickets.length)}</TableCell>
                                                </TableRow>
                                                <TableRow key={'apa'}>
                                                    <TableCell component="th" scope="row">Average Open Price (All Vendors)</TableCell>
                                                    <TableCell align="right">{currencyFormatter(event.open_tickets_all.reduce((n, { avg_ticket }) => n + avg_ticket, 0) / event.open_tickets_all.length)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </span>
        );
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }
}

export default Events;